<template>
    <div class="classify" :class="{ active: choose }" @click="change">
        <div
            class="bg classifycontent"
            :style="{ backgroundImage: 'url(' + img + ')' }"
        >
            <div class="box">
                <img :src="icon" alt="" class="icon" />
                <div class="name fontYahei">{{ name }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Classify',
    props: {
        img: {
            type: String,
            default: '',
        },
        icon: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            type: [String,Number],
            default: '',
        },
        choose: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        change() {
            this.$emit('changeChoose', this.value);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../styles/variables.scss';
.classify {
    height: 130px;
    overflow: hidden;
    &:hover {
        .classifycontent {
            transform: scale(1.1);
        }

        .box {
            background-color: transparentize($--color-warning, 0.2);
        }
    }

    &.active {
        .classifycontent {
            transform: scale(1.1);
        }

        .box {
            background-color: transparentize($--color-warning, 0.2);
        }
    }
}
.classifycontent {
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.3s;
}

.box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    background-color: transparent;

    .icon {
        width: 50px;
        height: 50px;
        display: block;
    }

    .name {
        font-size: 14px;
        color: rgba(255, 255, 255, 1);
        line-height: 19px;
        margin-top: 13px;
    }
}
</style>
